// 经感
import request from '../utils/request';
// 获取经感
export async function getExpressions(current) {
    try {
        return await request({
            url: `/posts/interviewExperienceFeel?current=${current}&size=20`,
            method: 'get',
        });
    } catch (error) {
        return {};
    }
}
// 发布经感
export async function publishExpression(data) {
    try {
        return await request({
            url: `/posts/interviewExperienceFeel`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }
}
// 浏览
export async function viewExps(id) {
    try {
        return await request({
            url: `/browse/` + id,
            method: 'post',
        });
    } catch (error) {
        return {};
    }
}