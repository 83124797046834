<template>
  <!-- 编辑经感 -->
  <div>
    <div class="" style="box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1)">
      <div class="house-nav">
        <router-link to="/">
          <img class="logo" src="@/assets/img/blue-logo.png" />
        </router-link>
        <div
          style="
            font-size: 18px;
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;
            padding-left: 20px;
            border-left: 1px solid rgba(0, 0, 0, 0.1);
            line-height: 36px;
            transform: translateY(10px);
          "
        >
          写经感
        </div>
        <div style="float: right">
          <el-button
            @click="publishExpression"
            :disabled="isPublished"
            style="border: 1px solid #1890ff; color: #1890ff"
            >发表</el-button
          >
        </div>
      </div>
    </div>

    <div class="container">
      <el-input
        size="large"
        v-model="title"
        placeholder="请输入标题..."
      ></el-input>
      <div id="myWangEditor">
        <Toolbar
          :editor="editorId"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />

        <!-- 编辑器 -->
        <Editor
          style="height: 670px"
          :editor="editorId"
          :defaultConfig="editorConfig"
          :defaultContent="getDefaultContent"
          :mode="mode"
          @onChange="onChange"
          @onCreated="onCreated"
        />
      </div>
    </div>
  </div>
</template>
<script>
import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import cloneDeep from "lodash.clonedeep";
import { publishExpression } from "@/service/interviewExpression";
export default {
  name: "EditExpression",
  components: { Editor, Toolbar },

  data() {
    return {
      title: "",
      content: "",
      myWangEditor: null,
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload?isPublic=true",
      isPublished: false,

      editorId: null,

      toolbarConfig: {},
      defaultContent: [],
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            fieldName: "file",
            server:
              process.env.VUE_APP_BASE_URL + "/common/upload?isPublic=true",
            customInsert(res, insertFn) {
              insertFn(res.result.accessibleUrl);
            },
            headers: {
              "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
            },
            maxFileSize: 5 * 1024 * 1024,
          },
          codeSelectLang: {
            codeLangs: [
              { text: "Javascript", value: "javascript" },
              { text: "Java", value: "java" },
              { text: "Python", value: "python" },
              { text: "PHP", value: "php" },
              { text: "C++", value: "cpp" },
              { text: "C", value: "c" },
            ],
          },
        },
      },
      mode: "default",
      curContent: [],
    };
  },
  methods: {
    publishExpression() {
      this.isPublished = true;
      if (this.title == "") {
        this.isPublished = false;
        return this.$message.error("请填写经感标题！");
      }
      if (this.content == "") {
        this.isPublished = false;
        return this.$message.error("请填写经感内容！");
      }
      publishExpression({
        title: this.title,
        content: this.content,
      })
        .then((res) => {
          if (res.success) {
            this.$message.success("发表经感成功！");
            setTimeout(() => {
              this.$router.push("/interviewExpression");
              this.isPublished = false;
            }, 1500);
          }
        })
        .catch((e) => {
          this.isPublished = false;
        });
    },
    onChange(editor) {
      this.curContent = editor.children;
      this.content = editor.getHtml();
    },
    onCreated(editor) {
      this.editorId = Object.seal(editor);
    },
  },
  mounted() {},
  computed: {
    getDefaultContent() {
      return cloneDeep(this.defaultContent);
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  width: 900px;
  padding: 20px;
  margin: 0 auto;
}
#myWangEditor {
  margin-top: 20px;
}
.house-nav {
  height: 54px;
  line-height: 54px;
  width: 900px;
  margin: 0 auto;

  img {
    width: 106px;
    margin-top: 8px;
  }
}
::v-deep .w-e-text-container,
::v-deep .w-e-toolbar {
  border: none !important;
}
</style>
